import React from "react";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Container,
  Card,
  Button,
  Alert,
  ListGroup,
  Table,
} from "react-bootstrap";
import MetlifeService from "../../services/MetlifeDataService";

import loading from "../../assets/css/img/loading-orange.gif";
import Accordion from "react-bootstrap/Accordion";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
// import "primeflex/primeflex.css";

import btn_down from "../../assets/css/img/arrow-down.png";
// import btn_up from "../../assets/css/img/arrow-up.png";
import NumberFormat from "react-number-format";

class BodyBancario extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [{}, {}],
      errorDesc: null,
      personal: this.props.state.personal,
      plan: this.props.state.plan,
      retirar: this.props.state.retirar,
      uf: this.props.state.uf,
      opcion: null,
      val_opcion: false,
      fields: [
        {
          banco: "12",
          glosaBanco: "BancoEstado",
          tipo_cuenta: "",
          cuenta: "",
          validarCuenta: "",
        },
        { banco: "", tipo_cuenta: "", cuenta: "", validarCuenta: "" },
      ],
    };
  }

  validateCuenta() {
    const { cuenta, validarCuenta } = this.state.fields[this.state.opcion];
    if (validarCuenta !== "" && cuenta !== validarCuenta) {
      this.setState({ errorMessage: "Los números de cuenta no coinciden" });
      return false;
    }
    this.setState({ errorMessage: "" });
    return true;
  }

  //   calling web services
  handleValidation() {
    let fields = this.state.fields;
    let errors = [{}, {}];
    let opcion = this.state.opcion;
    let formIsValid = true;

    if (opcion != null) {
      this.setState({ val_opcion: false });
      // Validacion de campos requeridos
      ["banco", "tipo_cuenta", "cuenta", "validarCuenta"].map((campo) => {
        switch (campo) {
          case "banco":
            if (!fields[opcion][campo])
              errors[opcion][campo] = "Selecciona un Banco";
            break;
          case "tipo_cuenta":
            if (!fields[opcion][campo])
              errors[opcion][campo] = "Selecciona un tipo de cuenta";
            break;
          case "cuenta":
            if (!fields[opcion][campo])
              errors[opcion][campo] = "Ingresa tu cuenta";
            break;
          case "validarCuenta":
            if (!fields[opcion][campo])
              errors[opcion][campo] = "Ingresa la validación de la cuenta";
            break;
          default:
            break;
        }
        return errors;
      });

      if (!this.validateCuenta()) {
        formIsValid = false;
      }

      if (Object.keys(errors[opcion]).length > 0) formIsValid = false;
    } else {
      this.setState({ val_opcion: true });
      formIsValid = false;
      this.setState({ isFetching: false });
    }
    this.setState({ errors: errors });

    return formIsValid;
  }

  updateBanco(e) {
    this.setState({ isFetching: true });

    e.preventDefault();
    let bancarios = this.state.fields[this.state.opcion];

    if (this.handleValidation()) {
      let request = {
        branch: this.state.plan.branch,
        product: this.state.plan.product,
        policy: this.state.plan.policy,
        idClient: this.state.personal.idClient,
        percentageRescue: this.state.retirar.porcentaje,
        numberTicket: this.state.plan.ticketNumber,
        mail: this.state.personal.email,
        bankAssociatedAccount: bancarios.banco,
        bankAccount: bancarios.cuenta,
        accountType2: bancarios.tipo_cuenta,
        // client data
        nombre: this.state.personal.nombre,
        nombrePoliza: this.state.plan.nombre,
        montoDisponibleUF: this.state.plan.monto_disponible_uf,
        montoDisponibleCL: this.state.plan.monto_disponible_clp,
        montoRetiroUF: this.state.retirar.rescate.uf,
        montoRetiroCL: this.state.retirar.rescate.clp,
        saldoUF: this.state.retirar.saldo.uf,
        saldoCL: this.state.retirar.saldo.clp,
        uf: this.state.uf,
        rut: this.state.personal.rut,
        sms: this.state.personal.sms,
        telefono: this.state.personal.telefono,
      };

      MetlifeService.requestRescate(this.state.personal.token, request)
        .then((res) => {
          this.setState({ isFetching: false });
          let dto = res.data.listCrewServiceDTO[0];

          // set ticket number to null
          let policyNumber = request.policy;
          this.state.personal.policies.forEach((item) => {
            if (policyNumber === item.policy) {
              item.ticketNumber = null;
            }
          });

          this.props.history.push({
            pathname: "/resumen",
            state: {
              personal: this.state.personal,
              plan: this.state.plan,
              retirar: this.state.retirar,
              uf: this.state.uf,
              bancarios: this.state.fields[this.state.opcion],
              solicitud: "Rescate de fondo de ahorro",
              num_solicitud: dto.numberProposal,
            },
          });
        })
        .catch((err) => {
          console.log("create rescue error", err);
          this.setState({ isFetching: false });
          let status = (err.response && err.response.status) || 500;

          if (status === 403 || status === 409 || status === 500) {
            this.setState({
              isFetching: false,
              errorDesc: "Lo sentimos, se produjo un error en la solicitud!",
            });
            this.props.history.push({
              pathname: "/bancarios",
              state: {
                personal: this.state.personal,
                plan: this.state.plan,
                retirar: this.state.retirar,
                uf: this.state.uf,
                bancarios: this.state.fields,
                num_solicitud: 0,
                solicitud: "Rescate de fondo de ahorro",
                forma_pago: "Deposito en cuenta",
              },
            });
          }
        });
    } else {
      this.setState({ isFetching: false });
    }
  }

  handlePercent(value) {
    if (value >= 0 && value <= 100) {
      let rescate = this.state.rescate;
      rescate.uf = (this.state.monto.uf * value) / 100;
      rescate.clp = (this.state.monto.clp * value) / 100;
      let saldo = this.state.saldo;
      saldo.uf = this.state.monto.uf - rescate.uf;
      saldo.clp = this.state.monto.clp - rescate.clp;
      this.setState({ rescate: rescate, saldo: saldo, porcentaje: value });
    }
  }

  render() {
    const bancos = [
      { label: "BANCO DE CHILE", value: "1" },
      { label: "BANCO SCOTIABANK", value: "14" },
      { label: "BANCO DE CRÉDITO E INVERSIONES", value: "16" },
      { label: "BANCO BICE", value: "28" },
      { label: "HSBC BANK USA", value: "31" },
      { label: "BANCO SANTANDER CHILE", value: "37" },
      { label: "BANCO ITAU CORPBANCA", value: "39" },
      { label: "BANCO SECURITY", value: "49" },
      { label: "SCOTIABANK AZUL (EX BANCO BBVA)", value: "504" },
      // { label: "TRANSBANK", value: "999" },
      // { label: "BANCO ESTADO", value: "12" },
      { label: "BANCO FALABELLA", value: "51" },
      { label: "BANCO RIPLEY", value: "53" },
      { label: "BANCO CONSORCIO", value: "55" },
      { label: "BANCO INTERNACIONAL", value: "9" },
      { label: "BANCO BTG PACTUAL CHILE", value: "59" },
    ];
    const cuentas_be = [
      { label: "Cuenta Corriente", value: "1" },
      { label: "Cuenta Vista", value: "4" },
      { label: "Ahorro", value: "3" },
    ];
    const cuentas = [
      { label: "Cuenta Corriente", value: "1" },
      { label: "Cuenta Vista", value: "4" },
    ];
    return (
      <Container className="mt-5 m-0 col-12 p-lg-3 p-0">
        <Row className="subtitle mb-3 px-3">
          Solicitar rescate Vida con Ahorro
        </Row>
        <Col className="p-0">
          <Row className="font-weight-bold mb-2 px-3">Datos para abono</Row>
          <Row className="mb-3 px-3">
            Selecciona como prefieres obtener tu rescate:
          </Row>
          {/* all */}
          <Accordion defaultActiveKey="0" className="planes_accordion">
            <Card>
              <Card.Header className="d-flex justify-content-between align-items-center">
                <span className="modal_label h5">
                  <Form.Check
                    inline
                    type="radio"
                    name="info_opt"
                    label="BancoEstado"
                    onChange={() => {
                      this.setState({ opcion: 0, val_opcion: false });
                    }}
                    id="inline-radio-0"
                  />
                </span>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  <img alt="arrow down" src={btn_down} />
                </Accordion.Toggle>
              </Card.Header>

              <Accordion.Collapse eventKey="0">
                <Card
                  style={{ fontSize: "17px" }}
                  className="align-middle border-0 "
                >
                  <Card.Body className="">
                    <Table className="table table-sm col-lg-12 col-12">
                      <tbody>
                        <div className="d-flex flex-md-row flex-column align-items-center p-1 justify-content-between">
                          <div className="p-1 w-75">
                            <strong>RUT titular (asegurado):</strong>
                          </div>
                          <div>{this.state.personal.rut}</div>
                        </div>

                        <div className="d-flex flex-md-row flex-column align-items-center p-1">
                          <div className="p-1 w-100">
                            <strong>Banco:</strong>
                          </div>
                          <div>{this.state.fields[0].glosaBanco}</div>
                        </div>

                        <div className="d-lg-flex justify-content-lg-between p-1">
                          <div className="p-1">
                            <strong>*Tipo de cuenta:</strong>
                          </div>
                          <div className="col-lg-6 p-0">
                            <Form.Group className="mt-1 mb-1 w-100">
                              <Form.Control
                                as="select"
                                value={this.state.fields[0].tipo_cuenta}
                                onChange={(e) => {
                                  const tipoCuenta = e.target.value;
                                  let fields = [...this.state.fields];
                                  fields[0].tipo_cuenta = tipoCuenta;

                                  if (tipoCuenta === "4") {
                                    const rut = this.state.personal.rut || "";
                                    const rutSinFormato = rut
                                      .replace(/\./g, "")
                                      .split("-")[0];
                                    fields[0].cuenta = rutSinFormato;
                                    fields[0].validarCuenta = rutSinFormato;
                                  } else {
                                    fields[0].cuenta = "";
                                    fields[0].validarCuenta = "";
                                  }

                                  this.setState({ fields });
                                }}
                                className="form-control-sm"
                                style={{ fontSize: "17px" }}
                              >
                                <option value="" disabled>
                                  Seleccione...
                                </option>
                                {cuentas_be.map((cuenta, index) => (
                                  <option key={index} value={cuenta.value}>
                                    {cuenta.label}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </div>
                        </div>

                        <div className="d-lg-flex justify-content-lg-between p-1">
                          <div className="p-1">
                            <strong>*Nr. de cuenta:</strong>
                          </div>
                          <div className="col-lg-6 p-0">
                            <NumberFormat
                              className="form-control-sm w-100 p-2"
                              allowEmptyFormatting={true}
                              onValueChange={(values) => {
                                const cleaned = (values.value || "")
                                  .replace(/\s/g, "") 
                                  .replace(/^0+/, "");
                                let fields = [...this.state.fields];
                                fields[0].cuenta = cleaned;
                                this.setState({ fields });
                              }}
                              name="cuenta"
                              value={this.state.fields[0].cuenta || ""}
                              placeholder="0000000000000"
                              style={{ fontSize: "15px" }}
                            />
                          </div>
                        </div>

                        <div className="d-lg-flex justify-content-lg-between p-1">
                          <div className="p-1">
                            <strong>*Validar Nr. de cuenta:</strong>
                          </div>
                          <div className="col-lg-6 p-0">
                            <NumberFormat
                              className="form-control-sm w-100 p-2"
                              allowEmptyFormatting={true}
                              onValueChange={(values) => {
                                const cleaned = (values.value || "")
                                  .replace(/\s/g, "") 
                                  .replace(/^0+/, "");
                                let fields = [...this.state.fields];
                                fields[0].validarCuenta = cleaned;
                                this.setState({ fields });
                              }}
                              name="validarCuenta"
                              value={this.state.fields[0].validarCuenta || ""}
                              placeholder="0000000000000"
                              style={{ fontSize: "15px" }}
                            />
                          </div>
                        </div>

                        {this.state.fields[0].cuenta !==
                          this.state.fields[0].validarCuenta && (
                          <div className="alert alert-danger mt-2">
                            Los números de cuenta no coinciden.
                          </div>
                        )}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Accordion.Collapse>
            </Card>
            {/* second  */}
            <Card>
              <Card.Header className="d-flex justify-content-between align-items-center">
                <span className="planes_head">
                  <Form.Check
                    inline
                    type="radio"
                    name="info_opt"
                    label="Otros Bancos"
                    className="modal_label h5"
                    onChange={(e) => {
                      this.setState({ opcion: 1, val_opcion: false });
                    }}
                    id={"inline-radio-1"}
                  />
                </span>
                <Accordion.Toggle as={Button} variant="link" eventKey={"1"}>
                  <img alt="arrow down" src={btn_down} />
                </Accordion.Toggle>
              </Card.Header>

              <Accordion.Collapse eventKey={"1"}>
                <Card.Body className="d-flex justify-content-center">
                  <Table
                    className="table table-sm col-12"
                    style={{ fontSize: "17px" }}
                  >
                    <tbody>
                      <div className="d-flex flex-md-row flex-column align-items-center p-1 justify-content-between">
                        <div className="p-1 w-75">
                          <strong>RUT titular (asegurado):</strong>
                        </div>
                        <div>{this.state.personal.rut}</div>
                      </div>

                      <div className="d-flex flex-md-row flex-column p-1">
                        <div className="p-1 w-100">
                          <strong>Banco:</strong>
                        </div>
                        <div className="col-md-6 p-0">
                          <Form.Group className="mt-1 mb-1 w-100">
                            <Form.Control
                              as="select"
                              value={this.state.fields[1].banco}
                              onChange={(e) => {
                                let fields = this.state.fields;
                                fields[1].banco = e.target.value;
                                this.setState({ fields: fields });
                                let errores = this.state.errors;
                                errores[1]["banco"] = null;
                                this.setState({ errors: errores });
                              }}
                              className="form-control-sm"
                              style={{ fontSize: "17px" }}
                            >
                              <option value="" disabled>
                                Seleccione un Banco...
                              </option>
                              {bancos.map((banco, index) => (
                                <option key={index} value={banco.value}>
                                  {banco.label}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </div>
                      </div>

                      <div className="d-flex flex-md-row flex-column p-1">
                        <div className="p-1 w-100">
                          <strong>*Tipo de cuenta:</strong>
                        </div>
                        <div className="col-md-6 p-0">
                          <Form.Group className="mt-1 mb-1 w-100">
                            <Form.Control
                              as="select"
                              value={this.state.fields[1].tipo_cuenta}
                              onChange={(e) => {
                                let fields = this.state.fields;
                                fields[1].tipo_cuenta = e.target.value;
                                this.setState({ fields: fields });
                                let errores = this.state.errors;
                                errores[1]["tipo_cuenta"] = null;
                                this.setState({ errors: errores });
                              }}
                              className="form-control-sm"
                              style={{ fontSize: "17px" }}
                            >
                              <option value="" disabled>
                                Seleccione tipo de cuenta...
                              </option>
                              {cuentas.map((cuenta, index) => (
                                <option key={index} value={cuenta.value}>
                                  {cuenta.label}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </div>
                      </div>

                      <div className="d-flex flex-md-row flex-column p-1">
                        <div className="p-1 w-100">
                          <strong>*Nr. de cuenta:</strong>
                        </div>
                        <div className="col-md-6 p-0">
                          <input
                            style={{ fontSize: "15px" }}
                            className="form-control-sm w-100 p-2"
                            type="text"
                            name="cuenta"
                            placeholder="0000000000000"
                            maxLength="13"
                            value={this.state.fields[1].cuenta || ""}
                            onChange={(e) => {
                              const value = e.target.value;
                              const numericValue = value
                                .replace(/\D/g, "")
                                .replace(/^0+/, "");
                              let fields = this.state.fields;
                              fields[1].cuenta = numericValue;
                              this.setState({ fields: fields });
                              let errores = this.state.errors;
                              errores[1]["cuenta"] = null;
                              this.setState({ errors: errores });
                            }}
                          />
                        </div>
                      </div>

                      <div className="d-flex flex-md-row flex-column p-1">
                        <div className="p-1 w-100">
                          <strong>*Validar Nr. de cuenta:</strong>
                        </div>
                        <div className="col-md-6 p-0">
                          <input
                            style={{ fontSize: "15px" }}
                            className="form-control-sm w-100 p-2"
                            type="text"
                            name="validarCuenta"
                            placeholder="0000000000000"
                            maxLength="13"
                            value={this.state.fields[1].validarCuenta || ""}
                            onChange={(e) => {
                              const value = e.target.value;
                              const numericValue = value
                                .replace(/\D/g, "")
                                .replace(/^0+/, "");
                              let fields = this.state.fields;
                              fields[1].validarCuenta = numericValue;
                              this.setState({ fields: fields });
                            }}
                          />
                        </div>
                      </div>

                      {this.state.fields[1].cuenta !==
                        this.state.fields[1].validarCuenta && (
                        <div className="alert alert-danger mt-2">
                          Los números de cuenta no coinciden.
                        </div>
                      )}
                    </tbody>
                  </Table>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>

          {this.state.val_opcion && (
            <Row className="mt-3 justify-content-center">
              <Col>
                <Alert className="msg-error">
                  <b>Seleccione un banco</b>
                </Alert>
              </Col>
            </Row>
          )}

          {this.state.errorDesc && (
            <Row className="mt-3 justify-content-center">
              <Col>
                <Alert className="msg-error-danger">
                  <b>{this.state.errorDesc}</b>
                </Alert>
              </Col>
            </Row>
          )}

          {(this.state.errors[1]["banco"] ||
            this.state.errors[1]["tipo_cuenta"] ||
            this.state.errors[1]["cuenta"]) && (
            <Row className="mt-3 justify-content-center">
              <Col>
                <ListGroup>
                  {this.state.errors[1]["banco"] && (
                    <ListGroup.Item variant="primary">
                      <b>{this.state.errors[1]["banco"]}</b>
                    </ListGroup.Item>
                  )}
                  {this.state.errors[1]["tipo_cuenta"] && (
                    <ListGroup.Item variant="primary">
                      <b>{this.state.errors[1]["tipo_cuenta"]}</b>
                    </ListGroup.Item>
                  )}
                  {this.state.errors[1]["cuenta"] && (
                    <ListGroup.Item variant="primary">
                      <b>{this.state.errors[1]["cuenta"]}</b>
                    </ListGroup.Item>
                  )}
                </ListGroup>
              </Col>
            </Row>
          )}
          {(this.state.errors[0]["tipo_cuenta"] ||
            this.state.errors[0]["cuenta"]) && (
            <Row className="mt-3 justify-content-center">
              <Col>
                <ListGroup>
                  {this.state.errors[0]["tipo_cuenta"] && (
                    <ListGroup.Item variant="primary">
                      <b>{this.state.errors[0]["tipo_cuenta"]}</b>
                    </ListGroup.Item>
                  )}
                  {this.state.errors[0]["cuenta"] && (
                    <ListGroup.Item variant="primary">
                      <b>{this.state.errors[0]["cuenta"]}</b>
                    </ListGroup.Item>
                  )}
                </ListGroup>
              </Col>
            </Row>
          )}

          <Row className="mt-3 md-3 justify-content-center text-justify p-3">
            De existir algún rechazo al realizar la transferencia electrónica a
            su cuenta bancaria, se emitirá un vale vista a su nombre que podrá
            retirar en cualquier sucursal a nivel nacional de Banco Estado.
          </Row>
          <Row className="mt-3 d-flex justify-content-center">
            {this.state.isFetching ? (
              <div className="btn-continuar ">
                <img className="center" src={loading} alt="loading" />
              </div>
            ) : (
              <button
                className="btn btn-warning btn-sm"
                type="button"
                onClick={this.updateBanco.bind(this)}
              >
                <span className="ui-button-text ui-c ">
                  Confirmar solicitud
                </span>
              </button>
            )}
          </Row>

          <Row className="d-flex justify-content-center">
            <p
              className="resend_code_label text-warning"
              onClick={this.props.logout}
              style={{
                cursor: "pointer",
              }}
            >
              Cerrar Sesión
            </p>
          </Row>
        </Col>
      </Container>
    );
  }
}

export default withRouter(BodyBancario);
