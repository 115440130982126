import React from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import MetlifeService from "../../services/MetlifeDataService";
import Planes from "./Planes";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.css";
import LoadingOverlay from "components/ConfirmarDatos/LoadingOverlay";

class BodyInicio extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      datos: null,
      plan: null,
      uf: 0,
      errors: {},
      certificado: "",
      show: false,
    };
    this.handlePlan = this.handlePlan.bind(this);

    this.state.datos = this.props.datos.personal;
  }
  //Funcion handlePlan
  handlePlan = (value) =>
    this.setState({ plan: value, errors: {}, show: false });

  async validatePlan() {
    let errors = {};
    this.setState({ errors: errors });
    let policies = this.state.planes;
    var bar = new Promise((resolve, reject) => {
      policies.forEach((item, index, array) => {
        if (item.ticketNumber === null) {
          errors["opcion"] =
            "En este momento no puedes iniciar un nuevo retiro de fondos ya que tienes uno anterior en curso.";
          this.setState({ errors: errors, show: true });
        }
        if (index === array.length - 1) resolve();
      });
    });

    return bar.then(() => {
      let errors = this.state.errors;
      if (
        this.state.plan === null &&
        typeof this.state.errors["opcion"] === "undefined"
      ) {
        errors["opcion"] = "Para continuar debes seleccionar un plan.";
        this.setState({ errors: errors, show: true });
      }
      if (typeof this.state.errors["opcion"] === "undefined") {
        return true;
      } else {
        return false;
      }
    });
  }

  // Calling web servicesb
  async solicitarRescate() {
    let valid = await this.validatePlan();
    if (valid) {
      this.props.history.push({
        pathname: "/personales",
        state: {
          personal: this.state.datos,
          plan: this.state.plan,
          uf: this.state.uf,
        },
      });
    }
  }
  // did mount component
  componentDidMount() {
    this.setState({ isFetching: true });

    let uf = "0";

    MetlifeService.getUF()
      .then((res) => {
        uf = res.data.value;
        this.setState({ uf: res.data.value });

        let planes = [];
        let policies = this.props.datos?.personal?.policies || [];

        policies.forEach((plan) => {
          let temp = {
            nombre: "Seguro de Muerte Accidental con Ahorro",
            fecha_inicio: plan.startDate,
            primas_pagadas: plan.numberApaymentPay,
            prima_mensual_clp: uf * (plan.apaymentUf || 0),
            prima_mensual_uf: plan.apaymentUf || 0,
            monto_pagado_clp: uf * (plan.amountPayApaymentIfUf || 0),
            monto_pagado_uf: plan.amountPayApaymentIfUf || 0,
            monto_disponible_clp: uf * (plan.availableBalance || 0),
            monto_disponible_uf: plan.availableBalance || 0,
            branch: plan.branch,
            policy: plan.policy,
            product: plan.product,
            ticketNumber: plan.ticketNumber,
            rescates: [],
            errorMessage: plan.errorMessage,
            errorCode: plan.errorCode,
          };

          planes.push(temp);
        });

        this.setState({
          planes: planes,
          certificado:
            "https://www.dropbox.com/s/essj2rir842rbkn/springer.pdf.pdf",
          isFetching: false,
        });
      })
      .catch((e) => {
        console.error("Error obteniendo UF o datos:", e);

        let planes = [];
        let policies = this.props.datos?.personal?.policies || [];

        policies.forEach((plan) => {
          let temp = {
            nombre: "Seguro de Muerte Accidental con Ahorro",
            fecha_inicio: plan.startDate,
            primas_pagadas: plan.numberApaymentPay,
            prima_mensual_clp: uf * (plan.apaymentUf || 0),
            prima_mensual_uf: plan.apaymentUf || 0,
            monto_pagado_clp: uf * (plan.amountPayApaymentIfUf || 0),
            monto_pagado_uf: plan.amountPayApaymentIfUf || 0,
            monto_disponible_clp: uf * (plan.availableBalance || 0),
            monto_disponible_uf: plan.availableBalance || 0,
            branch: plan.branch,
            policy: plan.policy,
            product: plan.product,
            ticketNumber: plan.ticketNumber,
            rescates: [],
            errorMessage: plan.errorMessage,
            errorCode: plan.errorCode,
          };

          planes.push(temp);
        });

        this.setState({
          planes: planes,
          certificado:
            "https://www.dropbox.com/s/essj2rir842rbkn/springer.pdf.pdf",
          isFetching: false,
        });
      });
  }

  render() {
    const { isRescateLoading } = this.state;
    return (
      <Container fluid className="mt-5 p-0">
        {isRescateLoading && <LoadingOverlay />}
        <React.Fragment>
          <Row className="subtitle mb-2">
            <Col>Consulta</Col>
          </Row>
          <Row className="info_right">
            <Col>
              <span>
                Esta es la información del saldo acumulado de cada unos de tus
                seguros con ahorro, si deseas retirar un monto total o parcial
                de alguno de ellos, debes seleccionar el seguro del cual deseas
                realizar el retiro y luego hacer click en el botón&nbsp;
                <b className="font-weight-bold">solicitar ahorros.</b>
              </span>
            </Col>
          </Row>
          <Row className="mt-3 mb-2">
            <Col className="p-2">
              {this.state.planes == null ? (
                <div className="btn-continuar">
                  <LoadingOverlay />
                  {/*   <img className="center" src={loading} alt="loading" /> */}
                </div>
              ) : (
                <Planes
                  planes={this.state.planes}
                  uf={this.state.uf}
                  handlePlan={this.handlePlan}
                  rut={this.state.datos.rut}
                  token={this.state.datos.token}
                  nombre={this.state.datos.nombre}
                />
              )}
            </Col>
          </Row>

          <Row className="info_right text-justify px-5 border border-1 border-warning pt-3">
            <Col>
              <ul>
                <li>
                  El 21 de abril de 2022, el Servicio de Impuestos Internos
                  (SII) definió que, tanto los rescates parciales como los
                  rescates totales que realicen los clientes, podrían quedar
                  afectos al pago del impuesto a la renta y las Compañías de
                  Seguros deberán retener dicho impuesto. Por lo tanto, el monto
                  final a recibir del rescate, podría ser menor al solicitado.
                </li>
                <li>
                  El asegurado puede realizar un rescate por mes, con un máximo
                  de 4 retiros al año parciales o total de la cuenta de
                  capacitación y ahorro.
                </li>
                <li>
                  <span className="font-weight-bold">Importante:</span>
                  &nbsp; se debe tener en consideración que puede existir un
                  cobro de prima en curso.
                </li>
              </ul>
            </Col>
          </Row>

          <div>
            {this.state.show && (
              <Row className="justify-content-center">
                <Col className="text-center">
                  <strong
                    className="text-danger p-0 p-lg-2"
                    style={{ fontWeight: "bold", fontSize: "13px" }}
                  >
                    {this.state.errors["opcion"]}
                  </strong>
                </Col>
              </Row>
            )}
            <Row className="mt-3 justify-content-center">
              <Col md={12} className="text-center">
                <button
                  className="btn btn-sm btn-warning"
                  type="button"
                  onClick={this.solicitarRescate.bind(this)}
                  // disabled={this.state.plan === null}
                >
                  <span className="ui-button-text ui-c">Solicitar ahorros</span>
                </button>
              </Col>
            </Row>
          </div>

          <Row className="d-flex justify-content-center">
            <p
              className="resend_code_label text-warning"
              style={{
                cursor: "pointer",
              }}
              onClick={this.props.logout}
            >
              Cerrar Sesión
            </p>
          </Row>
        </React.Fragment>
      </Container>
    );
  }
}

export default withRouter(BodyInicio);
