import React from "react";
import { withRouter } from "react-router-dom";
import { hideEmail, hideSms } from "../tools/Tools";
import { Row, Col, Form, Alert } from "react-bootstrap";
import MetlifeService from "../../services/MetlifeDataService";
import PopReenviar from "../Main/PopReenviar";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.css";

import ReactCodeInput from "react-verification-code-input";

class BodyCode extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      personal: null,
      metodo: null,
      message: "",
      resent_code: false,
      code: "",
      message_code: "",
      opcion: null,
    };

    //validate si viene desde el inicio
    if (this.props.location.state != null) {
      this.state.personal = this.props.location.state.personal;
      this.state.metodo = this.props.location.state.metodo;

      if (this.state.metodo === "0" && this.state.personal !== "undefined") {
        this.state.message =
          "Hemos enviado el código al teléfono " +
          (this.state.personal.sms === "undefined"
            ? ". Puede que tarde un momento en llegar."
            : hideSms(this.state.personal.sms) +
              ". Puede que tarde un momento en llegar.");
      }

      if (this.state.metodo === "1" && this.state.personal !== "undefined") {
        this.state.message =
          "Hemos enviado el código al mail " +
          (this.state.personal.email === "undefined"
            ? ". Puede que tarde un momento en llegar."
            : hideEmail(this.state.personal.email) +
              ". Puede que tarde un momento en llegar.");
      }
    } else {
      this.props.history.push("/");
    }
  }

  // Calling web services
  validateCode() {
    if (this.state.code.length !== 4) {
      this.setState({
        message_code: "- Debes ingresar el código de verificación.",
      });
      return;
    }

    this.setState({ message_code: "", isFetching: true });

    let data = {
      ticketNumber: this.state.personal.ticketNumbers,
      smsCode: this.state.code,
    };

    MetlifeService.validateCode(this.state.personal.token, data)
      .then((res) => {
        let responseData = res.data;

        if (responseData && responseData.listRClientPolDTO?.length > 0) {
          let policyData = responseData.listRClientPolDTO[0];

          let updatedPersonal = {
            ...this.state.personal,
            idClient: policyData.idClient || this.state.personal.idClient,
            policyNumber: policyData.policy || this.state.personal.policyNumber,
            nombre: `${policyData.clientFirstName || ""} ${
              policyData.clientLastName || ""
            }`.trim(),
            firstName: policyData.clientFirstName || "",
            lastName: policyData.clientLastName || "",
            email: policyData.clientEmail || "",
            sms: policyData.clientMobilPhoneNumber || "",
            telefono: policyData.clientPhoneNumber || "",
            ticketNumber:
              policyData.ticketNumber || this.state.personal.ticketNumber,
            sms_hide: `SMS - ${hideSms(policyData.clientMobilPhoneNumber)}`,
            email_hide: `Correo electrónico - ${hideEmail(
              policyData.clientEmail
            )}`,
            tel_hide: `Llamada telefónica - ${hideSms(
              policyData.clientPhoneNumber
            )}`,
            policies: responseData.listRClientPolDTO || [],
          };

          this.setState(
            { personal: updatedPersonal, isFetching: false },
            () => {
              this.props.history.push({
                pathname: "/main",
                state: { personal: updatedPersonal },
              });
            }
          );
        } else {
          this.setState({
            isFetching: false,
            message_code: "- No se encontró información de la póliza.",
          });
        }
      })
      .catch((err) => {
        console.log("Error > ", err);
        this.setState({
          error: true,
          isFetching: false,
          message_code: "- Código de autorización incorrecto.",
        });
        this.props.history.push({
          pathname: "/validateCode",
          state: { personal: this.state.personal },
        });
      });
  }

  saveOpcion(value) {
    // this.setState({ opcion: value });
  }

  render() {
    return (
      <React.Fragment>
        <Row className="info p-lg-4 p-2">
          <Col md={4} className="info_left">
            <div>
              {" "}
              <h5>Código de Verificación</h5>{" "}
            </div>
          </Col>
          <Col md={8} className="info_right">
            <Form>
              <Row>
                <Col>{this.state.message}</Col>
              </Row>
              <Row className="justify-content-left">
                <Col className="m-0">
                  <ReactCodeInput
                    className="container_code verify_code"
                    placeholder={["-", "-", "-", "-"]}
                    fields={4}
                    onChange={(val) => this.setState({ code: val })}
                    onComplete={(val) =>
                      this.setState({ code: val, message_code: "" })
                    }
                  />{" "}
                </Col>
              </Row>
              {this.state.message_code && (
                <Row className="mb-1">
                  <Col md={12} className="p-2 p-lg-0 mx-2 mx-lg-2">
                    <strong
                      className="text-danger p-0 p-lg-2"
                      style={{ fontWeight: "bold", fontSize: "13px" }}
                    >
                      {this.state.message_code}
                    </strong>
                  </Col>
                </Row>
              )}

              <Row className="d-flex justify-content-left">
                <Col>
                  <PopReenviar
                    email={
                      typeof this.state.personal === "undefined"
                        ? ""
                        : hideEmail(this.state.personal.email)
                    }
                    sms={
                      typeof this.state.personal === "undefined"
                        ? ""
                        : hideSms(this.state.personal.sms)
                    }
                    phone={
                      typeof this.state.personal === "undefined"
                        ? ""
                        : hideSms(this.state.personal.telefono)
                    }
                    personal={this.state.personal}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="ej">
                    {this.state.resent_code ? (
                      <span style={{ color: "#a94442" }}>
                        Se ha enviado un nuevo código
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>
              <Row className="btn_code d-flex justify-content-center mt-3">
                <Col>
                  <button
                    className="btn btn-sm btn-warning"
                    type="button"
                    onClick={this.validateCode.bind(this)}
                    // disabled={this.state.option === null ? "disabled" : ""}
                  >
                    <span>Ver mis ahorros</span>
                  </button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default withRouter(BodyCode);
