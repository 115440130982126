import React from "react";
import { withRouter } from "react-router-dom";
import { Table, Button, Container, Alert, Row } from "react-bootstrap";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.css";

class BodyPersonales extends React.Component {
  constructor(props) {
    super(props);
  
    this.state = {
      errors: {},
      fields: {},
      datos: this.props.datos,
    };
  
    var mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    this.validEmailRegex = RegExp(mailformat);

  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  //   calling web services
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //Validacion requerido
    ["telefono", "sms", "email"].map((campo) => {
      switch (campo) {
        case "sms":
          if (!this.state.datos.personal[campo]) {
            // if (!fields[campo]) errors[campo] = "El campo celular es equerido";
          }
          break;
        case "email":
          if (!this.state.datos.personal[campo]) {
            if (!fields[campo]) errors[campo] = "El campo email es requerido";
            else if (!this.validEmailRegex.test(fields["email"]))
              errors[campo] = "Email no es válido";
          }
          break;
        default:
          break;
      }

      return errors;
    });

    this.setState({ errors: errors });
    if (Object.keys(errors).length > 0) formIsValid = false;

    return formIsValid;
  }

  updatePersonal(e) {
    e.preventDefault();

    // saving in variables
    if (this.handleValidation()) {
      let datos = this.state.datos;
      let fields = this.state.fields;

      Object.keys(fields).map((clave) => {
        datos.personal[clave] = fields[clave];

        return datos;
      });
      this.setState({ datos: datos });

      // sent to the new page
      this.props.history.push({
        pathname: "/rescate",
        state: this.state.datos,
      });
    }
  }

  render() {
    if (!this.state.datos) {
      return <p>Cargando datos...</p>;
    }
    const { personal, errors } = this.state.datos;

    return (
      <Container className="mt-5 ">
        <h2 className="subtitle mb-3">Solicitar rescate Vida con Ahorro</h2>
        <h5 className="mb-4 mt-5 text-center">Datos del cliente</h5>
        <Table className="mt-5 d-flex d-md-table justify-content-center">
          <tbody>
            <tr>
              <th className="text-start">Nombre:</th>
              <td className="text-right">{`${personal.firstName} ${personal.lastName}`}</td>
            </tr>
            <tr>
              <th className="text-start">Celular:</th>
              <td className="text-right">
                {personal.sms}
                {this.state.errors["sms"] && (
                  <Alert className="msg-error-danger">
                    <p>{this.state.errors["sms"]}</p>
                  </Alert>
                )}
              </td>
            </tr>
            <tr>
              <th className="text-start">Email:</th>
              <td className="text-right">
                {personal.email}
                {this.state.errors["email"] && (
                  <Alert className="msg-error-danger">
                    <p>{this.state.errors["email"]}</p>
                  </Alert>
                )}
              </td>
            </tr>
            <tr>
              <th className="text-start">Telefono:</th>
              <td className="text-right">
                {personal.telefono}
                <div className="ej">
                  {this.state.errors["telefono"] ? (
                    <span style={{ color: "#a94442" }}>
                      {this.state.errors["telefono"]}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="mt-5 d-flex justify-content-center">
          <Button
            variant="warning"
            size="sm"
            onClick={this.updatePersonal.bind(this)}
            className="d-inline-block col-lg-2"
          >
            Continuar
          </Button>
        </div>
        <div className="d-flex justify-content-center mt-3">
          <p
            className="resend_code_label text-warning"
            onClick={this.props.logout}
            style={{
              cursor: "pointer",
            }}
          >
            Cerrar Sesión
          </p>
        </div>
      </Container>
    );
  }
}

export default withRouter(BodyPersonales);
