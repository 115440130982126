import React from "react";
import { withRouter } from "react-router-dom";
import { replaceAll, validaRut, hideEmail, hideSms } from "../tools/Tools";
import { Container, Row, Col, Form, Alert } from "react-bootstrap";
import MetlifeService from "../../services/MetlifeDataService";
import loading from "../../assets/css/img/loading-orange.gif";
import Popup from "./Popup";
import PopupRut from "./PopupRut";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.css";
import ReactTooltip from "react-tooltip";
import LoadingOverlay from "components/ConfirmarDatos/LoadingOverlay";

import ReCAPTCHA from "react-google-recaptcha";
const recaptchaRef = React.createRef();
const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

class BodyRut extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: false,
      cedula: "",
      rutcdv: "",
      showBtnIng: true,
      fields: {},
      errors: {},
      show: false,
      personal: {
        rut: null,
        nombre: null,
        firstName: null,
        lastName: null,
        sms: "",
        email: "",
        telefono: "",
        token: null,
        ticketNumber: "",
        policyNumber: "",
        policies: {
          policy: {
            descriptionBranch: "",
            descriptionProduct: "",
            descriptionState: "",
            endDate: "",
            nidServices: "",
            nstatus: "",
            numberApaymentPay: "",
            policy: "",
            product: "",
            startDate: "",
            status: "",
            ticketNumber: "",
          },
        },
      },
      mensaje: "",
      errorMsg: false,
      option: null,
      token: null,
      validar_rut: true,
    };

    //this.handlePlan = this.handlePlan.bind(this);
  }

  // Calling web services
  handlePlan = (value) => {
    if (value === null) {
      this.setState({ expired: "true" });
    } else {
      MetlifeService.getToken({ site: value })
        .then((res) => {
          if (res && res.headers) {
            let personal = this.state.personal;
            personal.token = res.headers.authorization;
            this.setState({
              personal: personal,
              token: res.headers.authorization,
            });
          } else {
            console.error("Respuesta inesperada del servidor:", res);
            this.setState({ error: true });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.redirect
          ) {
            // Redirecciona al URL error-recaptcha si el puntaje es menor a 0.5
            window.location.href = error.response.data.redirect;
            return;
          }
          console.error(error);
          this.setState({ error: true });
        });
    }
  };

  sendRut() {
    if (this.state.errors["rut"] !== "") return;

    this.setState({ isFetching: true });

    let clientId = this.state.rutcdv.split("-")[0].padStart(14, "0");

    MetlifeService.getData(
      { idInsured: clientId, idSponsor: null, state: "1", nuserCode: "2" },
      this.state.token
    )
      .then((res) => {
        let newToken = res.headers?.authorization;
        if (newToken) this.setState({ token: newToken });

        let policies = res.data.listRClientPolDTO || [];

        let ticketNumbers = policies
          .map((policy) => policy.ticketNumber)
          .filter(Boolean);

        let policyData = policies.length > 0 ? policies[0] : {};

        this.setState({
          personal: {
            rut: this.state.fields.rut,
            email: policyData.clientEmail || "",
            sms: policyData.sms || "",
            telefono: policyData.telefono || "",
            ticketNumbers: ticketNumbers,
            policyNumber: policyData.policy || "",
            token: newToken || this.state.token,
          },
          validar_rut: !policyData.clientEmail,
          errorMsg: !policyData.clientEmail,
          showBtnIng: !policyData.clientEmail,
          show: false,
          isFetching: false,
        });
      })
      .catch((err) => {
        console.log(err);

        let status =
          err.message === "Network Error" ? 503 : err.response?.status || 403;

        if (status === 403) {
          this.setState({ isFetching: false });
          this.props.history.push({ pathname: "/" });
          return;
        }

        this.setState({
          errorMsg: true,
          personal: {
            rut: this.state.fields.rut,
            email: "",
            sms: "",
            telefono: "",
            ticketNumbers: [],
          },
          validar_rut: true,
          showBtnIng: status !== 500,
          isFetching: false,
        });
      });
  }

  sendCode() {
    this.setState({ isFetching: true });

    let data = {
      ticketNumber: this.state.personal.ticketNumbers?.[0] || "",
      policyNumber: this.state.personal.policyNumber,
    };

    data.uri = this.state.option === "0" ? "/sms" : "/mail";
    MetlifeService.requestCode(this.state.token, data)
      .then((res) => {
        this.setState({ isFetching: false });
        this.props.history.push({
          pathname: "/validateCode",
          state: {
            personal: this.state.personal,
            metodo: this.state.option,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: true, show: true, isFetching: false });

        let errors = { rut: "Solicitud incorrecta" };

        this.props.history.push({
          pathname: "/",
          state: {
            personal: this.state.personal,
            metodo: this.state.option,
            errors: errors,
          },
        });
      });
  }

  // handle fields
  handleChange(field, e) {
    if (field === "optSms") {
      this.setState({ option: "0" });
    } else if (field === "optEmail") {
      this.setState({ option: "1" });
    }
  }

  handleRut(field, e) {
    let fields = this.state.fields;
    let labelRut = e.target.value.replace(/[.,-]/g, "");
    labelRut = labelRut.replace(/[^0-9kK]/g, "");
    if (labelRut.length > 9) {
      labelRut = labelRut.substring(0, 9);
    }
    if ("rut" in this.state.fields) {
      if (labelRut.length >= 2) {
        labelRut = labelRut.slice(0, -1) + "-" + labelRut.slice(-1);
      }
      if (labelRut.length >= 6) {
        labelRut = labelRut.slice(0, -5) + "." + labelRut.slice(-5);
      }
      if (labelRut.length >= 10) {
        labelRut = labelRut.slice(0, -9) + "." + labelRut.slice(-9);
      }
    }
    const rutFormatted = labelRut.replace(
      /^(\d{1,2})(\d{3})(\d{3})([kK0-9])$/,
      "$1.$2.$3-$4"
    );

    fields[field] = rutFormatted;
    this.setState({ fields });

    if (e.type === "blur") {
      let errors = {};
      let rut = labelRut.replace(/[^0-9kK-]/g, "");
      rut = rut.toUpperCase();

      if (!validaRut(rut)) {
        errors["rut"] = "Ingresa un RUT válido.";
        this.setState({
          errors: errors,
          show: true,
        });
      } else {
        errors["rut"] = "";
        this.setState({
          errors: errors,
          show: false,
        });
        this.setState({ rutcdv: rut });
      }
    }
    if (e.type === "change") {
      this.setState({ show: false });
    }
  }

  checkAccount(value) {
    let rut = replaceAll(this.state.personal.rut.toString(), [",", "."], "");
    let data = { rut: rut, serie_rut: this.state.cedula };

    MetlifeService.validateRut(this.state.token, data)
      .then((res) => {
        this.setState({ isFetching: false });
        if (res.data === "SI") {
          this.setState({ mensaje: "" });
          this.props.history.push({
            pathname: "/main",
            state: this.state.personal,
          });
        } else {
          this.setState({
            mensaje:
              "Según nuestros registros el número de serie ingresado no corresponde a su RUT, favor intentar con otro medio de ingreso a la plataforma.",
          });
        }
        this.setState({ cedula: "" });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: true });
        this.setState({ isFetching: false });
        this.setState({ cedula: "" });
        this.setState({ mensaje: "Servicio no disponible." });
      });
  }

  saveCedula(value) {
    this.setState({ cedula: value });
  }
  componentDidMount() {
    const interval = 25 * 60 * 1000;
    recaptchaRef.current.execute();

    setInterval(() => {
      recaptchaRef.current.reset();
      recaptchaRef.current.execute();
    }, interval);
  }

  cleanPopup() {
    this.setState({
      rut: "",
      rutcdv: "",
      showBtnIng: true,
      mensaje: "",
      validar_rut: true,
      errors: {},
      show: false,
      fields: {},
      isFetching: false,
      personal: {
        sms: "",
        email: "",
        telefono: "",
      },
    });
  }

  render() {
    return (
      <Container fluid>
        <Row>
          <Col md={4} className="info_left">
            <div>Consulta aquí tus seguros con ahorro*</div>
          </Col>
          <Col md={8}>
            <Form
              onSubmit={() => {
                recaptchaRef.current.execute();
              }}
            >
              <Row className="mb-3">
                <Col className="info_right">
                  Ingrese su RUT y seleccione la opción donde quiere recibir su
                  código de verificación.
                </Col>
              </Row>
              <Row className="mb-3">
                <Col className="info_right">
                  Este código le permitirá ingresar al portal y acceder a su
                  información
                </Col>
              </Row>
              <React.Fragment>
                <Row className="mb-3">
                  <Col>
                    <b>R.U.T</b>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col className="col-mb-3">
                    {this.state.show && (
                      <ReactTooltip>{this.state.errors["rut"]}</ReactTooltip>
                    )}
                    <input
                      type="text"
                      name="rut"
                      placeholder="11.111.111-1"
                      onChange={this.handleRut.bind(this, "rut")}
                      onBlur={this.handleRut.bind(this, "rut")}
                      value={this.state.fields["rut"] || ""}
                      data-tip
                      data-for="msgErrorRut"
                    />
                    {this.state.show && (
                      <Row className="mb-1">
                        <Col md={12}>
                          <strong className="text-danger text-sm">
                            {this.state.errors["rut"]}
                          </strong>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>

                {this.state.showBtnIng && (
                  <Row className="mb-3 ">
                    <Col className="">
                      <button
                        className="btn btn-sm btn-warning d-flex align-items-center"
                        type="button"
                        onClick={this.sendRut.bind(this)}
                      >
                        <span className="ui-button-text ui-c ">
                          Ingresar&nbsp;
                        </span>

                        {this.state.isFetching && (
                          <div
                            className="spinner-border text-light"
                            role="status"
                            style={{
                              width: "1rem",
                              height: "1rem",
                            }}
                          >
                            <span className="sr-only">Cargando...</span>
                          </div>
                        )}
                      </button>
                    </Col>
                  </Row>
                )}
              </React.Fragment>

              <Row>
                <Col>{this.state.errorMsg ? <PopupRut /> : ""}</Col>
              </Row>

              {this.state.validar_rut ? (
                <div></div>
              ) : (
                <React.Fragment>
                  {!this.state.showBtnIng && (
                    <Row className="info_label">
                      <Col>Opción código de verificación</Col>
                    </Row>
                  )}
                  {!this.state.showBtnIng &&
                    this.state.personal.sms.length > 0 && (
                      <Row>
                        <Col>
                          <Form.Check
                            inline
                            label={this.state.personal.sms_hide || "SMS - "}
                            type="radio"
                            onChange={this.handleChange.bind(this, "optSms")}
                            name="info_opt"
                            id="inline-radio-1"
                            disabled={
                              this.state.personal.sms === null ||
                              this.state.personal.sms.length === 0
                                ? "disabled"
                                : ""
                            }
                          />
                        </Col>
                      </Row>
                    )}

                  {!this.state.showBtnIng &&
                    this.state.personal.email.length > 0 && (
                      <Row className="info_form">
                        <Col>
                          <Form.Check
                            inline
                            label={
                              this.state.personal.email ||
                              "Correo electrónico - "
                            }
                            type="radio"
                            onChange={this.handleChange.bind(this, "optEmail")}
                            name="info_opt"
                            id="inline-radio-2"
                            disabled={
                              this.state.personal.email === null ||
                              this.state.personal.email.length === 0
                                ? "disabled"
                                : ""
                            }
                          />
                        </Col>
                      </Row>
                    )}

                  {!this.state.showBtnIng &&
                    this.state.personal.telefono.length > 0 && (
                      <Row className="info_form">
                        <Col>
                          <Form.Check
                            inline
                            label={
                              this.state.personal.tel_hide ||
                              "Llamada telefónica - "
                            }
                            type="radio"
                            onChange={this.handleChange.bind(this, "optSms")}
                            name="info_opt"
                            id="inline-radio-1"
                            disabled="disabled"
                          />
                        </Col>
                      </Row>
                    )}

                  {!this.state.showBtnIng && (
                    <Row>
                      <Col>
                        ¿Tienes problemas para ingresar? &nbsp;
                        <Popup
                          rut={this.state.personal.rut}
                          mensaje={this.state.mensaje}
                          checkAccount={this.checkAccount.bind(this)}
                          saveCedula={this.saveCedula.bind(this)}
                          cleanPopup={this.cleanPopup.bind(this)}
                        />
                      </Col>
                    </Row>
                  )}
                  {!this.state.showBtnIng && (
                    <Row>
                      <Col>
                        <button
                          className="btn btn-sm btn-warning mt-2"
                          type="button"
                          onClick={this.sendCode.bind(this)}
                          disabled={
                            this.state.option === null ? "disabled" : ""
                          }
                        >
                          <span className="ui-button-text ui-c ">
                            Solicitar código
                          </span>{" "}
                          {this.state.isFetching && (
                            <div
                              className="spinner-border text-light"
                              role="status"
                              style={{
                                width: "1rem",
                                height: "1rem",
                              }}
                            >
                              <span className="sr-only">Cargando...</span>
                            </div>
                          )}
                        </button>
                      </Col>
                    </Row>
                  )}
                </React.Fragment>
              )}
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={siteKey}
                onChange={this.handlePlan}
              />
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(BodyRut);
